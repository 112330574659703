import burger from './modules/burger';
import modal from './modules/modal';
import './modules/slider';
import './modules/validate';
// import './modules/posterImg';
import './modules/anchorScroll';
import mask from './modules/mask';


window.addEventListener('DOMContentLoaded', () => {
    'use strict';
    
    document.addEventListener('click', () => {
        document.querySelectorAll('.choice-mobile__list').forEach(el => {
            el.classList.remove('show')
        })
    })

    burger();
    modal();
    mask('#phoneNumber');

    // function setCookie ( ) {
    //     const cookieValue = document.cookie
    //         .split('; ')
    //         .find(row => row.startsWith('biatv-cookie='))
    //         ?.split('=')[1];

    //     // Парсимо JSON
    //     const cookieData = JSON.parse(decodeURIComponent(cookieValue));

    //     // Видаляємо поле 'beginning_at' з об'єкту utmDataCurrent
    //     if (cookieData.utmDataCurrent) {
    //     delete cookieData.utmDataCurrent.beginning_at;
    //     }

    //     // Встановлюємо значення в input елементи
    //     document.querySelector('input[name="UtmCampaignStr"]').value = cookieData.utmDataCurrent.utm_campaign || "(not_set)";
    //     document.querySelector('input[name="UtmContentStr"]').value = cookieData.utmDataCurrent.utm_content || "(not_set)";
    //     document.querySelector('input[name="UtmMediumStr"]').value = cookieData.utmDataCurrent.utm_medium || "(not_set)";
    //     document.querySelector('input[name="UtmSourceStr"]').value = cookieData.utmDataCurrent.utm_source || "(not_set)";
    //     document.querySelector('input[name="UtmTermStr"]').value = cookieData.utmDataCurrent.utm_term || "(not_set)";
    // }
    // console.log('asdasd');
    // setTimeout(() => {
    //     setCookie();
    //     console.log(document.querySelector('input[name="UtmCampaignStr"]').value);
    //     console.log(document.querySelector('input[name="UtmContentStr"]').value);
    //     console.log(document.querySelector('input[name="UtmMediumStr"]').value);
    //     console.log(document.querySelector('input[name="UtmSourceStr"]').value);
    //     console.log(document.querySelector('input[name="UtmTermStr"]').value);
    // }, 1000);

});


