$(function(){
    $('.promo__poster_big').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
    })
    $('.promo__poster_mob').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
    })

    $('.cars__wrapper').slick({
        arrows: false,
        responsive: [
            {
                breakpoint: 5024,
                settings: 'unslick'
            }, 
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }    
        ]
    });
});
