import Cookies from "js-cookie";

const modal = () => {

    const showModal = () => {
        setTimeout(() => {
            let isAnyModalOpen = false
            const modal = document.querySelector(".popup__consultation")
            const countModals = document.querySelectorAll(".js-popup")?.length;
            const countModalsHide = document.querySelectorAll(".js-popup.hide")?.length;

            if (countModals != countModalsHide) {
                isAnyModalOpen = true;
            }

            if (!isAnyModalOpen) {
                modal.removeAttribute('style');
                modal.classList.remove('hide');
                Cookies.set("dontShowModalFormAwaitKz", true, { expires: 1 });
            }
        }, 30000);
        document.removeEventListener("scroll", showModal); // Видаляємо обробник подій після скасування
    };

    if (!Cookies.get("dontShowModalFormAwaitKz")) {
        document.addEventListener("scroll", showModal);
    }

    function bindModal(triggerSelector, modalSelector, closeSelector) {
        
        const trigger = document.querySelectorAll(triggerSelector),
            modal = document.querySelector(modalSelector),
            close = document.querySelector(closeSelector);

        trigger.forEach(item => {
            item.addEventListener('click', (e) => {
                if (e.target) {
                    e.preventDefault();
                }

                window.pupup_btn = item.innerText.trim().toLowerCase();

                // if (item.getAttribute('data-promo') == 'price') {
                //     formInput.forEach(input => {
                //         input.value = '';
                //         input.setAttribute('style', '');
                //         input.classList.remove('valid');
                //     });
                // }
                modal.removeAttribute('style');
                modal.classList.remove('hide');
                // document.body.classList.add('no-scroll');
            });
        });

        close.addEventListener('mousedown', () => {
            modal.classList.add('hide');
            // document.body.classList.remove('no-scroll');
            // clearForm();
        });

        modal.addEventListener('mousedown', (e) => {
            if (e.target === modal) {
                modal.classList.add('hide');
                // document.body.classList.remove('no-scroll');
                // clearForm();
            }
        });
    }

    bindModal('.popup_call_btn', '.popup__consultation', '.popup__consultation .popup__close');
    bindModal('.popup_price_btn', '.popup__price', '.popup__price .popup__close');
    bindModal('.popup_choice_btn', '.popup__choice', '.popup__choice .popup__close');
    bindModal('.choice-mobile__form-btn', '.popup__choice', '.popup__choice .popup__close');


    // popup choice body ------------

    function initMobileDropdown(toggle, dropdown, dropdownItem, input) {
        const $toggle = document.querySelector(toggle),
            $dropdown = document.querySelector(dropdown),
            $dropdownItems = document.querySelectorAll(dropdownItem),
            $input = document.querySelector(input);

        $toggle.addEventListener('click', e => {
            e.stopPropagation();
            document.querySelectorAll('.choice-mobile__list').forEach(el => {
                el.classList.remove('show')
            })
            $dropdown.classList.toggle('show');
        });

        $dropdownItems.forEach($dopdownItem => {
            $dopdownItem.addEventListener('click', () => {
                const itemValue = $dopdownItem.getAttribute('data-body');
                $input.setAttribute('value', itemValue);

                $dropdown.classList.toggle('hide');
                $dropdown.classList.toggle('show');
            })

        });
    }

    initMobileDropdown('.choice-mobile__list_fuel', '.choice-mobile__list_fuel .choice-mobile__list', '.choice-mobile__list_fuel .choice-mobile__list li', '.choice-mobile__list_fuel input')
    initMobileDropdown('.choice-mobile__list_body', '.choice-mobile__list_body .choice-mobile__list', '.choice-mobile__list_body .choice-mobile__list li', '.choice-mobile__list_body input')

    // list number ================================

    function showListFuel() {
        const inputChoice = document.querySelectorAll('.popup__form_choice input[name=MscFuelType]'),
            listChoice = document.querySelector('.popup__choice_list_fuel'),
            listItem = listChoice.querySelectorAll('.popup__choice_list_fuel .popup__choice_item'),
            listItemLink = document.querySelectorAll('.popup__choice_list_fuel .popup__choice_item a');

        inputChoice.forEach(input => {
            input.addEventListener('click', () => {
                listChoice.classList.toggle('show');
                listChoice.classList.toggle('hide');
            });
            listItem.forEach(item => {
                item.addEventListener('click', (e) => {
                    switch (item.getAttribute('data-fuel')) {
                        case 'hybrid':
                            input.setAttribute('value', 'Гибрид');
                            break;
                        case 'electro':
                            input.setAttribute('value', 'Электро');
                            break;
                        case 'petrol':
                            input.setAttribute('value', 'Бензин');
                            break;
                        case 'diesel':
                            input.setAttribute('value', 'Дизель');
                            break;
                    }
                    listChoice.classList.toggle('show');
                    listChoice.classList.toggle('hide');
                });
            });
            listItemLink.forEach(item => {
                item.addEventListener('click', () => {
                    listChoice.classList.toggle('show');
                    listChoice.classList.toggle('hide');
                })
            });
        });
    }

    showListFuel();

    function showListBody() {
        const inputChoice = document.querySelectorAll('.popup__form_choice input[name=MscCarBody]'),
            listChoice = document.querySelector('.popup__choice_list_body'),
            listItem = listChoice.querySelectorAll('.popup__choice_list_body .popup__choice_item'),
            listItemLink = document.querySelectorAll('.popup__choice_list_body .popup__choice_item a');

        inputChoice.forEach(input => {
            input.addEventListener('click', () => {
                listChoice.classList.toggle('show');
                listChoice.classList.toggle('hide');
            });
            listItem.forEach(item => {
                item.addEventListener('click', (e) => {
                    switch (item.getAttribute('data-body')) {
                        case 'sedan':
                            input.setAttribute('value', 'Седан');
                            break;
                        case 'pickup':
                            input.setAttribute('value', 'Пикап');
                            break;
                        case 'crossover':
                            input.setAttribute('value', 'Кроссовер');
                            break;
                        case 'minivan':
                            input.setAttribute('value', 'Минивен');
                            break;
                        case 'coupe':
                            input.setAttribute('value', 'Купэ');
                            break;
                        case 'wagon':
                            input.setAttribute('value', 'Универсал');
                            break;
                        case 'cabriolet':
                            input.setAttribute('value', 'Кабриолет');
                            break;
                        case 'sport':
                            input.setAttribute('value', 'Спорткар');
                            break;
                    }
                    listChoice.classList.toggle('show');
                    listChoice.classList.toggle('hide');
                });
            });
            listItemLink.forEach(item => {
                item.addEventListener('click', () => {
                    listChoice.classList.toggle('show');
                    listChoice.classList.toggle('hide');
                })
            });
        });
    }

    showListBody();


    // popup__choice ================================

    function choiceForm(popupFormInput, popupBtn, inputForm, mobile=false) {
        const popupFormChoiceInput = document.querySelectorAll(popupFormInput),
            popupChoiceBtn = document.querySelector(popupBtn),
            inputFormChoice = document.querySelectorAll(inputForm);

        popupChoiceBtn.addEventListener('click', () => {
            inputFormChoice.forEach(input => {

                if (input.checked || mobile && !!input.value) {
                    popupFormChoiceInput.forEach(inputChoice => {
                        switch (input.getAttribute('name')) {
                            case 'MscFuelType':
                                if (inputChoice.getAttribute('name') == 'MscFuelType') {
                                    inputChoice.setAttribute('value', input.value)
                                    inputChoice.style.cssText = `
                                        background-color: rgba(29, 194, 75, 0.1);
                                        border-radius: 4px 4px 0px 0px;
                                    `;
                                    document.querySelector('.popup__form_choice input[name=MscFuelType] ~ span').style.display = 'block';
                                }
                                break;
                            case 'MscCarBody':
                                if (inputChoice.getAttribute('name') == 'MscCarBody') {
                                    inputChoice.setAttribute('value', input.value)
                                    inputChoice.style.cssText = `
                                        background-color: rgba(29, 194, 75, 0.1);
                                        border-radius: 4px 4px 0px 0px;
                                    `;
                                    document.querySelector('.popup__form_choice input[name=MscCarBody] ~ span').style.display = 'block';
                                }
                                break;
                        }
                    })
                }

            })
        });
    }

    choiceForm('.popup__form_choice input', '.popup_choice_btn', '.choice-form input');
    choiceForm('.popup__form_choice input', '.choice-mobile__form-btn', '.choice-mobile__form input', true);

    // popup__price =================================

    function priceForm() {
        const carItems = document.querySelectorAll('.cars-item'),
            carBtns = document.querySelectorAll('.cars__wrapper .popup_price_btn'),
            inputPopupPrice = document.querySelectorAll('.popup__price input');

        carBtns.forEach((btn, i) => {
            btn.addEventListener('click', () => {
                carItems.forEach((item, j) => {
                    if (i === j) {
                        inputPopupPrice.forEach(input => {
                            switch (input.getAttribute('name')) {
                                case 'MscAutoBrandStr':
                                    input.setAttribute('value', item.querySelector('[data-brand]').innerText);
                                    input.style.cssText = `
                                        background-color: rgba(29, 194, 75, 0.1);
                                        border-radius: 4px 4px 0px 0px;
                                    `;
                                    document.querySelector('.popup__form input[name=MscAutoBrandStr] ~ span').style.display = 'block';
                                    break;
                                case 'MscAutoModelStr':
                                    input.setAttribute('value', item.querySelector('[data-model]').innerText);
                                    input.style.cssText = `
                                        background-color: rgba(29, 194, 75, 0.1);
                                        border-radius: 4px 4px 0px 0px;
                                    `;
                                    document.querySelector('.popup__form input[name=MscAutoModelStr] ~ span').style.display = 'block';
                                    break;
                            }
                        });
                    }
                });
            });
        });

    }

    priceForm();
};

export default modal;


