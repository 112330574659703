const burger = () => {
    // burger ===================
    const burger = document.querySelector('.burger'),
        burgerClose = document.querySelector('.burger_close'),
        menu = document.querySelector('.header-nav'),
        menuItem = document.querySelectorAll('.header-nav__item');

    // function scroll() {
    //     if (burger.classList.contains('burger_active') && menu.classList.contains('header-nav_active')) {
    //         document.body.style.overflow = 'hidden';
    //     } else {
    //         document.body.style.overflow = '';
    //     }
    // }

    burger.addEventListener('click', () => {
        // burger.classList.add('burger_active');
        burger.style.display = 'none';
        burgerClose.style.display = 'block';
        menu.classList.add('header-nav_active');
        // scroll();
    });

    burgerClose.addEventListener('click', () => {
        burgerClose.style.display = 'none';
        burger.style.display = 'flex';
        menu.classList.remove('header-nav_active');
        // scroll();
    });

    menuItem.forEach(item => {
        item.addEventListener('click', () => {
            if (window.innerWidth < 992) {
                burgerClose.style.display = 'none';
                burger.style.display = 'flex';
                burger.classList.toggle('burger_active');
            }
            menu.classList.toggle('header-nav_active');
            // scroll();
        });
    });
};

export default burger;