$('a.anchor').on('click', function(event) {
    var $anchor = $(this);

    $('html, body')
        .stop()
        .animate(
            {
                scrollTop: $($anchor.attr('href')).offset().top - 60
            },
            {
                duration: 1000,
                specialEasing: {
                    width: 'linear',
                    height: 'easyInOutCubic'
                }
            }
        )
    event.preventDefault();
});
